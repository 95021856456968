.loading-btn {
  position: relative;
}

.loading-btn:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2em;
  height: 2em;
  margin-top: -1em;
  margin-left: -1em;
  border: .25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
  z-index: 10;
}

.loading-btn:after {
  content: '';
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  background: white;
  opacity: 0.6;
  z-index: 9;
}

.loading-input {
  position: relative;
}

.loading-input:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 1.5em;
  right: 20px;
  width: 1.5em;
  height: 1.5em;
  margin-top: -0.75em;
  border: .25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
  z-index: 9;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}


.loading, .loading-big {
  position: relative;
}

.loading:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border: .25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
  z-index: 9;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading:after, .loading-big:after {
  content: '';
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  background: white;
  opacity: 0.6;
  z-index: 8;
}

.loading-big:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-top: -25px;
  margin-left: -25px;
  border: .25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner .8s ease infinite;
  z-index: 1001;
}

.widget.loading:after {
  opacity: 1;
}
