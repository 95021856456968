.page {
    padding-bottom: 48px;
}
.page-title {
    font-family: Rubik;
    font-weight: 600;
    font-size: 36px;
    color: $primary;
    letter-spacing: -0.03px;
    text-align: center;
    margin-bottom: 55px;
    @include media-breakpoint-down(sm) {
        font-size: 30px;
    }
}

.signup-link {
    text-align: center;
    color: #787878;
}

.page-subtitle {
    font-family: Rubik;
    color: $primary;
    text-align: center;
    font-weight: 500;
    font-size: 32px;
    line-height: 36px;
    @include media-breakpoint-down(sm) {
        font-size: 26px;
    }
}

.page-description {
    font-family: Rubik;
    color: #787878;
    text-align: center;
    font-size: 26px;
    margin-bottom: 60px;
    @include media-breakpoint-down(sm) {
        font-size: 20px;
    }
}

.no-results {
    text-align: center;
    padding: 48px 24px;
    color: $text-muted;
}
