.charge-box {
    flex-direction: row;
    background: white;
    border-radius: $border-radius;
    font-size: 26px;
    cursor: pointer;

    &__header {
        padding: 38px 32px;
        display: flex;
        flex-direction: row;
    }

    &.expanded {
        .charge-box__caret {
            transform: rotate(90deg);
        }
    }

    &__date {
        flex: 1;
        color: $primary;
        font-weight: 600;
    }
    &__info {
        flex: 1;
        color: $gray;
    }
    &__caret {
        color: #404040;
        font-size: 24px;
        width: 26px;
        transition: 0.3s;
    }

    &__body {
        border-top: 1px solid $gray;
        color: $secondary;
        padding: 24px 0;
        margin: 0 32px;
        background: white;
        display: flex;
        flex-direction: row;

        &-col {
            flex: 1;
        }
        &-title {
            font-weight: 600;
        }
        &-value {
            color: $gray;
        }
    }

    &__footer {
        background: $secondary;
        color: white;
        padding: 24px 38px;
        display: flex;
        flex-direction: row;
        &-col {
            flex: 1;
        }
        &-title {

        }
        &-value {
            font-size: 36px;
            font-weight: 600;
        }
    }
}
