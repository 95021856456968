.page-wrapper {
    &:before {
        width: 0;
        height: 0;
        border-style: solid;
        border-top-width: 95px;
        border-right-width: 0;
        border-bottom-width: 205px;
        border-left-width: 100vw;
        border-color: #e77825 transparent transparent #e77825;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        //z-index: 1;
        @include media-breakpoint-down(sm) {
            border-bottom-width: 125px;
        }
    }
    background: rgb(237,246,250);
    background: linear-gradient(132deg, rgba(237,246,250,1) 0%, rgba(237,246,250,1) 12%, rgba(248,248,248,1) 100%);
}

#site-header {
    height: 342px;
    padding-top: 30px;
    @include media-breakpoint-down(sm) {
        height: 242px;
    }
    .logo {
        height: 170px;
        width: 170px;
        z-index: 101;
        position: relative;
        @include media-breakpoint-down(sm) {
            height: 120px;
            width: 120px;
        }
    }
}

.site-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.menu-bar-inner {
    display: flex;
    align-items: center;
    height: 31px;
}
.menu-bar {
    height: 30px;
    width: 30px;
    cursor: pointer;
    display: inline-block;
    z-index: 101;
    position: relative;
    margin-left: 24px;
    font-size: 30px;
    color: white;
    line-height: 30px;
    &:hover {
        color: white;
    }
    i {
        line-height: 31px;
        height: 31px;
        display: block;
    }
    .counter {
        position: absolute;
        top: -5px;
        right: -10px;
        min-width: 20px;
        height: 20px;
        background: $secondary;
        color: white;
        border-radius: 10px;
        font-size: 14px;
        display: block;
        text-align: center;
        line-height: 16px;
        padding: 2px 6px;
    }
}

.menu-bar-inner {
    @include media-breakpoint-down(sm) {
        margin-right: 24px;
    }
}
.ham {
    display: inline-block;
    width: 30px;
    height: 4px;
    border-radius: 3px;
    position: relative;
    top: -1px;
    background-color: #fff;
    vertical-align: middle;
    white-space: nowrap;
    transition: all ease 0.3s;

    &::before, &::after {
        position: absolute;
        content: '';
        display: inline-block;
        width: 30px;
        height: 4px;
        border-radius: 3px;

        left: 0;
        background-color: #fff;
        outline: 1px solid transparent;
        transition: all ease 0.3s
    }

    &:before {
        top: -8px
    }

    &:after {
        top: 8px
    }
}

.menu-item {

}

#full-screen-menu {
    background-color: rgba(8,117,147,0.86);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    z-index: 100;
    opacity: 0;
    transition: opacity 0.3s;
    visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.main-navigation {
    text-align: center;
    padding: 100px 0;
    .main-menu {
        display: block;
        list-style: none;
        padding: 0;
        @include media-breakpoint-down(sm) {
            padding-top: 64px;
        }
        @media screen and (max-height: 600px) {
            padding-top: 164px;
        }
    }
    .menu-item {
        padding: 12px 0;
        display: block;
        @media screen and (max-height: 600px) {
            padding: 6px 0;
        }
    }
    .menu-link {
        font-family: Rubik;
        font-style: normal;
        font-size: 30px;
        letter-spacing: .5px;
        text-transform: lowercase;
        color: #fff;
        text-decoration: none;
        font-weight: 600;
    }
    .menu-social {
        margin-top: 45px;
        ul {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            list-style: none;
            padding: 0;
            li {
                margin: 0 16px;
            }
        }
        a {
            color: white;
            font-size: 24px;
            text-decoration: none;
            transition: color 0.3s;
            &:hover {
                color: #929292
            }
        }
    }
}

#site-header.opened {
    .ham {
        background: transparent;
        &::before {
            transform: translateY(8px) rotateZ(-45deg);
        }
        &::after {
            transform: translateY(-8px) rotateZ(45deg);
        }
    }

    #full-screen-menu {
        visibility: visible;
        opacity: 1;
    }
}

#site-footer {
    background: $secondary;
    color: $white;
    padding-top: 60px;
    padding-bottom: 60px;

    //.container {
    //    max-width: 900px;
    //}
}
