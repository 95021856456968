.btn {
    &.--w350 {
        width: 350px;
    }

    &.--w300 {
        width: 300px;
    }

    &.--w250 {
        width: 250px;
    }

    + .btn {
        @include media-breakpoint-up(md) {
            margin-left: 16px;
        }
    }

    &-block {
        width: 100%;
    }
    max-width: 100%;
}

.btn-outline-primary{
    &:hover, &:active {
        color: white
    }
}

.btn-primary{
    color: white;
    &:hover, &:active {
        color: white
    }
}
