.balance-box {
    border-radius: $border-radius;
    overflow: hidden;
    margin-bottom: 30px;
    &__header {
        padding: 44px;
        background: $secondary;
        color: white;
    }
    &__body {
        background: white;
        color: $secondary;
        padding: 24px 38px;
        font-size: 26px;
    }
    &__text {
        font-size: 26px;
        line-height: 30px;
        text-align: center;
    }
    &__amount {
        font-size: 36px;
        line-height: 40px;
        text-align: center;
        font-weight: 600;
    }
}

.service-grid {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    row-gap: 20px;
    width: 100%;
    @include media-breakpoint-down(md) {
        grid-template-columns: 100%;
    }
    &__item {
        display: flex;
        background: white;
        border-radius: $border-radius;
        color: $primary;
        padding: 18px 36px;
        align-items: center;

        &-icon {
            display: flex;
            align-items: center;
            font-size: 70px;
            width: 100px;
            @include media-breakpoint-down(md) {
                font-size: 50px;
            }
        }
        &-title {
            flex: 1;
            text-transform: uppercase;
            font-size: 26px;
            font-weight: 500;
            @include media-breakpoint-down(md) {
                font-size: 20px;
            }
        }
    }
}

.select-box {
    flex-direction: row;
    background: white;
    border-radius: $border-radius;
    color: $primary;
    padding: 38px 32px;
    font-size: 26px;
    cursor: pointer;

    &__selected-item {
        display: flex;
        align-items: center;
        &.expanded {
            .select-box__caret {
                transform: rotate(90deg);
            }
        }
    }
    &__text {
        flex: 1;
    }
    &__caret {
        color: #404040;
        font-size: 24px;
        width: 26px;
        transition: 0.3s;
    }
    &__item {
        border-bottom: 1px solid whitesmoke;
        &:last-child {
            border-bottom: none;
        }
        //a.selected {
        //    &::before {
        //        display: inline;
        //        content: '[';
        //    }
        //    &::after {
        //        display: inline;
        //        content: ']';
        //    }
        //}
    }
}
