.consumption-card {
    border-radius: $border-radius;
    overflow: hidden;
    margin-bottom: 30px;
    &__header {
        background: $secondary;
        color: white;
        padding: 24px 38px;
        font-size: 26px;
        @include media-breakpoint-down(sm) {
            padding: 16px 24px;
            font-size: 18px;
        }
    }
    &__body {
        background: white;
        color: $secondary;
        padding: 24px 38px;
        padding-left: 12px;
        //display: flex;
        //flex-direction: row;
        //flex-wrap: wrap;
        @include media-breakpoint-down(sm) {
            padding: 16px 24px;
            padding-left: 12px;
        }
    }
}
