.message {
    border-radius: $border-radius;
    overflow: hidden;
    margin-bottom: 30px;
    &__header {
        background: $secondary;
        color: white;
        padding: 24px 38px;
        font-size: 26px;
    }
    &__body {
        background: white;
        color: $secondary;
        padding: 24px 38px;
        display: flex;
        flex-direction: row;
        .flaticon-chevron {
            color: $secondary;
        }
    }
}
