@import "./bootstrap/bootstrap.scss";
@import "loaders";
@import "buttons";
@import "plugins_3rd/flatpickr.css";
@import "plugins_3rd/tooltip";

@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600&display=swap');

@import "theme/page";
@import "theme/dashboard";
@import "theme/balance";
@import "theme/consumption";
@import "theme/statement";
@import "theme/charges";
@import "theme/header";
@import "theme/faq";
@import "theme/messages";

a {
    text-decoration: none;
    color: initial;
}
b {
    font-weight: 600;
}
html {
    height: 100%;
}

body {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #F8F8F8;
}

#site-body {
    flex: 1 0 auto;
    padding-bottom: 36px;
}

#site-footer {
    flex-shrink: 0;
    .footer-row {

        display: flex;
        flex-direction: row;
        > div {
            flex: 1
        }
        @include media-breakpoint-down(md) {
            display: block;
        }
        * {
            color: white
        }
    }
}
.footer-item {
    display: inline-block;
    margin-right: 16px;
    margin-bottom: 16px;
    @include media-breakpoint-down(md) {
        text-align: center;
        display: block;
        margin-right: 0;
    }
}
.footer-social {
    text-align: right;
    @include media-breakpoint-down(md) {
        text-align: center;
        margin-top: 24px;
    }
    ul {
        list-style-type: none;
        display: inline-block;
        margin-bottom: 0;
        padding-left: 0;
        li {
            display: inline-block;
            &+li {
                margin-left: 16px;
                @include media-breakpoint-down(md) {
                    margin-left: 24px;
                }
            }

            a {
                color: white;
            }
        }
    }
}

.box {
    border-radius: $border-radius;
    overflow: hidden;
    &__header {
        background: $secondary;
        color: white;
        padding: 24px 38px;
        @include media-breakpoint-down(sm) {
            padding: 16px 24px;
        }
    }
    &__body {
        color: $secondary;
        padding: 24px 38px;
        background: white;
        @include media-breakpoint-down(sm) {
            padding: 16px 24px;
        }
    }
    &__footer {
        background: $secondary;
        color: white;
        padding: 24px 38px;
    }
}

.table {
    border-radius: $border-radius;
    overflow: hidden;

    thead {
        background: $secondary;
        color: white;
        th {
            padding: 24px 24px;
        }
    }

    tbody {
        color: $secondary;
        background: white;
        td {
            padding: 13px 24px;
        }
        tr:first-child {
            td {
                padding-top: 24px;
            }
        }
        tr:last-child {
            td {
                padding-bottom: 24px;
            }
        }
    }

    .form-control {
        background: #F8F8F8;
    }
}

.inline-form {
    display: flex;
    flex-direction: row;
    .form-group {
        margin-bottom: 8px;
    }
    .form-group + .form-group {
        @include media-breakpoint-up(sm) {
            margin-left: 8px;
        }
    }
    @include media-breakpoint-down(sm) {
        display: block;
    }
}

.form-group {
    label {
        font-size: 16px;
        color: $text-muted
    }
}

.nav-flex {
    display: flex;
    > li {
        flex: 1
    }
}

.reading-info {
    @include media-breakpoint-down(sm) {
        font-size: 16px;

        h3 {
            font-size: 22px;
        }
    }
}

.reading-tabs {
    background: whitesmoke;
    color: white;
    li {
        a {
            border-radius: 0 !important;
            border: 0 !important;
            opacity: 0.6;
            color: white;
            height: 50px;
            &.tab-Gas {
                background: #ffe70b;
            }
            &.tab-Heat {
                background: #ff8b00;
            }
            &.tab-Electricity {
                background: #16c79a;
            }
            &.tab-Water {
                background: #11698e;
            }
        }
        &.is-active {
            a {
                opacity: 1;
            }
        }
        @include media-breakpoint-down(sm) {
            .tab-text {
                display: none;
            }
            .tab-icon {
                font-size: 32px;
            }
            text-align: center;
        }
    }
    table {
        width: 100%;
        border-radius: 0;
        @include media-breakpoint-down(sm) {
            td {
                padding: 12px 18px;
                font-size: 16px;
                input {
                    padding: 12px 12px;
                    min-width: 100px !important;
                    font-size: 16px;
                }
                .btn {
                    font-size: 16px;
                }
            }
            th {
                padding: 12px 18px;
                font-size: 16px;
            }
        }
    }

    .tab-Gas table th {
        background: #ffe70b;
    }
    .tab-Heat table th {
        background: #ff8b00;
    }
    .tab-Electricity table th {
        background: #16c79a;
    }
    .tab-Water table th {
        background: #11698e;
    }
}

.tab-title-mobile {
    @include media-breakpoint-up(sm) {
        display: none;
    }
}

.toast-message {
    font-size: 16px;
}

.empty-text {
    padding: 36px 0;
    text-align: center;
    font-weight: 500;
    color: gray;
}

.table-responsive {
    overflow-x: auto;
}

.toast-container {
    width: max-content;
    max-width: calc(100% - 24px);
     >div {
         max-width: 100%;
     }
}

.form-group.has-error {
    input {
        border-color: #dc3545;
        padding-right: calc(1.25em + 2rem);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(0.3125em + 0.5rem) center;
        background-size: calc(0.625em + 1rem) calc(0.625em + 1rem);
    }
}

.form-group.error {
    .new-control {
        color: #dc3545;
    }
}
