.balance-transaction {
    border-radius: $border-radius;
    overflow: hidden;
    margin-bottom: 30px;
    &__header {
        background: $secondary;
        color: white;
        padding: 24px 38px;
        font-size: 26px;
        @include media-breakpoint-down(sm) {
            padding: 16px 24px;
            font-size: 18px;
        }
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    &__body {
        background: white;
        color: $secondary;
        padding: 24px 38px;
        padding-left: 12px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        @include media-breakpoint-down(sm) {
            padding: 16px 24px;
            padding-left: 12px;
        }
        a.btn {
            box-shadow: none !important;
            transform: rotate(0deg);
            transition: 0.3s;
            padding: 6px 12px;
            height: 28px;
            margin-top: 14px;
            &.rotate {
                transform: rotate(90deg);
            }
        }
        .flaticon-chevron {
            color: $secondary;
        }
    }
    &__info {
        flex: 1;
    }
    &__title {
        color: $primary;
        font-weight: 600;
        i {
            margin-left: 6px;
            color: $secondary;
        }
    }
    &__description {
        font-size: 20px;
    }
    &__amount {
        font-size: 36px;
        font-weight: 600;
        text-align: right;
        @include media-breakpoint-down(sm) {
            margin-top: 20px;
            width: 100%;
            text-align: left;
            margin-left: 47px;
        }
    }
    &__closing_balance {
        font-size: 18px;
        font-weight: 600;
        text-align: right;
        color: $gray;

        @include media-breakpoint-down(sm) {
            text-align: left;
        }
    }
}
