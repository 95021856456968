.question {
    border-radius: $border-radius;
    overflow: hidden;
    margin-bottom: 30px;
    &__header {
        background: $secondary;
        color: white;
        padding: 24px 38px;
        font-size: 26px;
        position: relative;
        padding-right: 60px;
        cursor: pointer;
    }
    &__chevron {
        color: white;
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -20px;
        height: 48px;
        width: 48px;
        line-height: 48px;
        text-align: center;
        transition: 0.1s;
        i {
            display: inline-block;
            height: 48px;
            width: 48px;
            line-height: 48px;
        }
        &.rotate {
            transform: rotate(90deg);
        }
    }
    &__body {
        background: white;
        color: $secondary;
        padding: 24px 38px;
        display: flex;
        flex-direction: row;
        .flaticon-chevron {
            color: $secondary;
        }
    }
}
